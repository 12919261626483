// App.js
import React from "react";
import { Link, navigate } from "gatsby";


class App extends React.Component {
 
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    navigate('app/dashboard');
  }

  render() {
    return ( 
      <div>
        Redirecting to <Link to="app/dashboard">Dashboard</Link> ...
      </div>
    );
  }
}

export default App;
